// now this file is an artifact
import type { LocationQueryValue } from 'vue-router';
import { BASE_METHOD, FetchApiOrigin } from '~/common/API/baseApi';
import { Role } from '~/common/constants';
import { UserStatus, type UserState } from '~/interfaces/user';
import { useUserStore } from '~/store/UserModule';

type LoginAuthorizeBody = {
  login: string;
  password: string;
};

export default class AuthService {
  static authKey = '';

  static user: UserState | null = null;

  static contextUserId: number | null = null;

  // static authorize = async (body: LoginAuthorizeBody) => {
  //   const { 'x-api-token': token } = (await fetchApi({
  //     body,
  //     origin: FetchApiOrigin.DATA_API_ORIGIN,
  //     method: BASE_METHOD.POST,
  //     endpoint: '/auth/login',
  //   })) as AuthorizeResponse;
  //   ApiKey.set(token);
  // };

  // static generateAuthKey = async () => {
  //   const { authKey } = (await fetchApi({
  //     method: BASE_METHOD.POST,
  //     endpoint: '/user/telegram/bot/generate-auth-key',
  //   })) as { authKey: string };
  //   this.authKey = authKey;
  //   const url = `${useRuntimeConfig().public.RESOLVE_TELEGRAM_AUTHORIZE_PREFIX}${authKey}`;
  //   window.open(url, '_blank');
  // };

  // static checkTelegramAuthorize = async () => {
  //   const { id, accessToken } = (await fetchApi({
  //     endpoint: '/user/telegram/bot/check-auth',
  //     params: { authKey: this.authKey },
  //   })) as Partial<{ id: number; accessToken: string }>;

  //   if (!id || !accessToken) throw new Error('');
  //   ApiKey.set(accessToken);
  // };

  // static fetchUser = async () =>
  //   fetchApi({
  //     endpoint: '/user/me',
  //     origin: FetchApiOrigin.DATA_API_ORIGIN,
  //   }) as Promise<UserState>;

  // static register = async (email: string) =>
  //   fetchApi({
  //     endpoint: '/user/registration/request',
  //     method: BASE_METHOD.POST,
  //     body: {
  //       email,
  //       role: Role.ROLE_DATA_LIGHT,
  //     },
  //   });

  // static confirmEmail = (body: { code: UseRouteQuery }) =>
  //   fetchApi({
  //     endpoint: '/user/registration/confirm',
  //     method: BASE_METHOD.POST,
  //     body,
  //   });

  // static getUser = async () => {
  //   const user = await AuthService.fetchUser();
  //   useUserStore().setUser({
  //     ...user,
  //     status: UserStatus.REGISTERED,
  //   });
  // };
}
